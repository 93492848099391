<template>
  <div>

    <template>
      <!-- First Row -->
      <b-row>
        <b-col
          cols="12"
          xl="9"
          lg="8"
          md="7"
        >
          <view-info-card />
        </b-col>
        <b-col
          cols="12"
          xl="3"
          lg="4"
          md="5"
        />
      </b-row>
    </template>

  </div>
</template>

<script>
import {
  BRow, BCol,
} from 'bootstrap-vue'

import ViewInfoCard from './ViewInfoCard.vue'

export default {
  components: {
    BRow,
    BCol,
    ViewInfoCard,
  },
}
</script>

<style>

</style>
